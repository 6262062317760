import React, { ReactElement } from 'react'
import { P } from '@farewill/ui'
import { CHARITY_AMOUNT_RAISED } from 'config'

const Intro = (): ReactElement => (
  <>
    <P size="L">
      All of us are going to die. It’s a fundamental, tragic, romantic part of
      what it is to be human. But the services that should be supporting us
      haven’t changed in decades.
    </P>
    <P>
      From writing your will to planning a funeral, the way we deal with death
      is formal, complicated and expensive. Instead of feeling supported, people
      feel lost.
    </P>
    <P>
      But it doesn’t need to be this way. We started Farewill to make the
      experience warmer, brighter and friendlier.
    </P>
    <P>
      We’re the UK’s biggest will writer and one of the leading funeral
      companies in the country. Our generous customers have pledged{' '}
      {CHARITY_AMOUNT_RAISED} to charities, through donations left in their
      wills.
    </P>
    <P>
      We’re backed by top investors who are as excited by our mission as we are,
      and we’ve won awards for the work we’ve done. But there’s a lot more to
      do.
    </P>
  </>
)

export default Intro
