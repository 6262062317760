import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Button, Wrapper } from '@farewill/ui'
import { smallCaps } from '@farewill/ui/helpers/text'
import { BREAKPOINT, COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { screenMax, screenMin } from '@farewill/ui/helpers/responsive'

import { SingleLeverJob } from 'views/general/templates/LeverJob'
import BreakpointSwitch from 'components/BreakpointSwitch'

const StyledWrapper = styled(Wrapper)`
  ${screenMin.m`
    position: sticky;
    top: ${GTR.M};
  `}
`

const StyledDL = styled.dl`
  dt {
    ${smallCaps}
    color: ${COLOR.BLACK};
    margin: 0;
  }
  dd {
    font-size: ${FONT.SIZE.L};
    margin: 0 0 ${GTR.M};
  }

  ${screenMax.s`
    display: table;
    clear: both;

      dd {
        font-size: ${FONT.SIZE.M};
        margin: 0;
        line-height: 1.1;
      }

      dt {
        &::after {
          content: ":";
          padding-right: ${GTR.XXS};
        }
      }

      dt, dd {
        float: left;
      }

      dd + dt, dd + dd {
        clear: both;
      }

      dt + dd:not(:last-child) {
        margin-bottom: ${GTR.S};
      }
  `}
`

const Sidebar = ({ jobData }: { jobData: SingleLeverJob }): ReactElement => {
  const locations = [...jobData.categories.allLocations, jobData.workplaceType]
  return (
    <StyledWrapper>
      <StyledDL>
        {locations && (
          <>
            <dt>Location</dt>
            <dd>{locations.join(' / ')}</dd>
          </>
        )}
        {jobData.categories.team && (
          <>
            <dt>Team</dt>
            <dd>{jobData.categories.team}</dd>
          </>
        )}
        {jobData.categories.commitment && (
          <>
            <dt>Hours</dt>
            <dd>{jobData.categories.commitment}</dd>
          </>
        )}
      </StyledDL>
      <BreakpointSwitch
        breakAt={BREAKPOINT.S}
        aboveComponent={
          <Button.Primary href={jobData.applyUrl} target="_blank" wide size="M">
            Apply for this job
          </Button.Primary>
        }
      />
    </StyledWrapper>
  )
}

export default Sidebar
