/* eslint-disable react/no-danger */
import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'
import { BREAKPOINT, COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { Button, Divider, Grid, H, Wrapper } from '@farewill/ui'
import { screenMax } from '@farewill/ui/helpers/responsive'
import styled from 'styled-components'
import { navigate } from '@reach/router'

import DefaultLayout from 'layouts/DefaultLayout'
import Values from 'views/general/public/Careers/Values'
import { ROUTES } from 'views/general/routes'
import BreakpointSwitch from 'components/BreakpointSwitch'

import Sidebar from './components/Sidebar'
import Banner from './components/Banner'
import Intro from './components/Intro'
import DiversityBanner from './components/DiversityBanner'

const StyledLeverContent = styled(Wrapper)`
  li {
    list-style: none;
    display: flex;
    margin-bottom: ${GTR.XS};

    &::before {
      content: '•';
      margin-right: ${GTR.S};
      color: ${COLOR.ACCENT.PRIMARY};
    }
  }

  span {
    font-size: ${FONT.SIZE.M} !important;
  }

  b,
  b span {
    font-size: ${FONT.SIZE.L} !important;
    margin-bottom: ${GTR.S};
    display: block;
    color: ${COLOR.BLACK};

    ${screenMax.m`
    font-size: ${FONT.SIZE.M} !important;
    `}
  }

  b,
  strong,
  h {
    color: ${COLOR.BLACK};
  }
`

const StyledButton = styled(Button.Primary)`
  ${screenMax.s`
    max-width: 420px;
    width: 100%;
  `}
`

export type SingleLeverJob = {
  id: string
  text: string
  lists: {
    text: string
    content: string
  }[]
  description: string
  categories: {
    allLocations: string[]
    team: string
    commitment: string
  }
  applyUrl: string
  workplaceType: string
}

type LeverJobProps = {
  data: {
    leverJob: {
      postings: SingleLeverJob[]
    }
  }
  pageContext: {
    slug?: string
  }
}

const LeverJob = ({
  data,
  pageContext,
}: LeverJobProps): ReactElement | null => {
  const jobData = data.leverJob.postings.find(
    (posting) => posting.id === pageContext.slug
  )

  if (!jobData) {
    navigate(ROUTES.PUBLIC.CAREERS.PATH)
    return null
  }

  return (
    <Wrapper background={COLOR.WHITE}>
      <DefaultLayout
        title={`${jobData.text} - Farewill`}
        description="Find out more about the role and what it's like to work with the award winning team at Farewill."
        headerColor={COLOR.ACCENT.PRIMARY}
      >
        <Banner heading={jobData.text} ctaUrl={jobData.applyUrl} />

        <Grid container>
          <Grid.Item spanFromM={4} spanFromL={3}>
            <Sidebar jobData={jobData} />
          </Grid.Item>
          <Grid.Item spanFromM={8} spanFromL={6}>
            <Intro />

            <StyledLeverContent
              margin={['M', 0]}
              dangerouslySetInnerHTML={{ __html: jobData.description }}
            />

            {jobData.lists.map((list) => (
              <StyledLeverContent key={list.text} margin={['S', 0]}>
                <H tag="h2" size="S">
                  {list.text}
                </H>
                <div dangerouslySetInnerHTML={{ __html: list.content }} />
              </StyledLeverContent>
            ))}
            <BreakpointSwitch
              breakAt={BREAKPOINT.S}
              belowComponent={
                <Wrapper margin={['L', 0]}>
                  <StyledButton
                    href={jobData.applyUrl}
                    target="_blank"
                    wide
                    size="M"
                  >
                    Apply for this job
                  </StyledButton>
                </Wrapper>
              }
            />
            <DiversityBanner />
          </Grid.Item>
        </Grid>
        <Divider container />
        <Values />
      </DefaultLayout>
    </Wrapper>
  )
}

export const query = graphql`
  query ($id: ID!) {
    leverJob(postings: { elemMatch: { id: { eq: $id } } }) {
      postings {
        id
        text
        lists {
          text
          content
        }
        description
        categories {
          allLocations
          team
          commitment
        }
        applyUrl
        workplaceType
      }
    }
  }
`

export default LeverJob
