import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Divider, Grid, H, Image, P, Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

const StyledWrapper = styled(Wrapper)`
  border-radius: ${GTR.S};
  padding: ${GTR.M};
`

const StyledList = styled.ol`
  list-style: none;
  counter-reset: list;
  padding: 0;

  & > li {
    position: relative;
    margin-bottom: ${GTR.M};
    counter-increment: list;
    padding-left: ${GTR.XL};
  }
  & > li::before {
    color: ${COLOR.BLACK};
    position: absolute;
    left: 0;
    top: 0;
    content: counter(list);
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9565 0.0800942C19.7244 -0.427904 23.5804 1.49209 26.5764 5.51207C29.5724 9.53206 30.1324 12.204 29.9764 15C29.7204 19.568 27.6004 23.264 25.8484 25.692C24.0964 28.12 21.8084 30.016 17.6964 30C13.6684 29.984 6.36447 28.556 3.29648 24.932C0.228495 21.308 -0.727502 17.032 0.552494 11.96C1.83249 6.88807 4.18848 0.588092 11.9565 0.0800942Z' fill='%23FFDF4E'/%3E%3C/svg%3E%0A");
  }
`

const DiversityBanner = (): ReactElement => (
  <StyledWrapper margin={['L', 0, 0]} background={COLOR.ACCENT.PRIMARY_10}>
    <Grid>
      <Grid.Item>
        <Image
          path="illustrations/cropped/blob-with-family"
          width={190}
          alt="Diversity banner"
        />
      </Grid.Item>
      <Grid.Item>
        <H tag="h3" size="S">
          We’re building a diverse, inclusive team
        </H>
        <P>
          To change the way the world deals with death we need a team that
          celebrates diversity in all its forms. We’re building a workplace
          where everyone is treated fairly and respectfully.
        </P>
        <P>
          If you’re excited about our mission but you’re worried you don’t tick
          every box in the job description, get in touch anyway. Ability and
          attitude are just as important as experience.
        </P>
        <H tag="h4" size="XS">
          Let us know how we can support you
        </H>
        <P>
          If you need any adjustments in our interview process to help set you
          up for success, just let us know.
        </P>
      </Grid.Item>
      <Grid.Item>
        <Divider />
      </Grid.Item>
      <Grid.Item>
        <H tag="h3" size="S">
          Our recruitment process
        </H>
        <P>
          We like to keep things simple, so this is a broad outline of our
          process. If there are technical tasks or additional steps, we’ll let
          you know the first time we talk.
        </P>
      </Grid.Item>
      <Grid.Item>
        <StyledList>
          <li>Apply for the job through the link on this page.</li>
          <li>
            You’ll have an initial chat over Google Meet with a member of our
            recruiting team, so we can find out more about your experience and
            ambitions. You can also ask any questions you have about working at
            Farewill.
          </li>
          <li>
            If we think you’re a good match for the role, we’ll book you in for
            follow up interviews with the hiring team. These will be a chance to
            meet some of the people you’ll be working with, and demonstrate your
            core skills for the job.
          </li>
          <li>That’s it! If everyone’s happy, we’ll make you an offer.</li>
        </StyledList>
      </Grid.Item>
    </Grid>
  </StyledWrapper>
)

export default DiversityBanner
