import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { H, Button } from '@farewill/ui'
import { screenMax } from '@farewill/ui/helpers/responsive'

import { SingleLeverJob } from 'views/general/templates/LeverJob'
import YellowBanner from 'components/YellowBanner'

const StyledYellowBanner = styled(YellowBanner)`
  min-height: 380px;
  display: flex;
  align-items: center;
`

const StyledButton = styled(Button.White)`
  ${screenMax.s`
    max-width: 420px;
    width: 100%;
  `}
`

const Banner = ({
  heading,
  ctaUrl,
}: {
  heading: SingleLeverJob['text']
  ctaUrl: SingleLeverJob['applyUrl']
}): ReactElement => (
  <StyledYellowBanner textureStyle="B">
    <H tag="h1" size="XL" decorative maxWidthInColumns={6}>
      {heading}
    </H>
    <StyledButton href={ctaUrl} target="_blank" wide size="M">
      Apply for this job
    </StyledButton>
  </StyledYellowBanner>
)

export default Banner
